<template>
  <div id="index">
    <div class="i-wrap">
      <div class="banner-c">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, i) in bList" :key="i">
              <img style="cursor:pointer" :src="`${baseUrl}/${item.file}`" alt="" @click="GObannerItem(item)" />
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
      <div class="dList">
        <!-- <div style="text-align: center;font-size: 30px;font-weight: 600;margin-top: 3%;margin-bottom: 2%;" >精品案例</div>
        <div style="width: 75%;height: 200px;margin: 0 auto; display: flex;align-items: center;">

          <div style="float: left;margin: auto 0;width: 30px;margin-right:20px;height: 30px;background: #fff;border-radius: 50%;text-align: center;line-height: 30px;border: 1px solid #ccc;cursor: pointer" @click="leftClick"> <span class="el-icon-arrow-left"></span></div>
            <div style="width: 100%; display: flex;align-items: center;justify-content: space-between;;overflow-x: scroll;padding-top: 25px;" ref="wrapperCon" class="scroll_wrapper">

              <div v-for="(item,index) in imgList" :key="index" style="width: 25%;height: 300px; display: flex;align-items: center;justify-content: center;margin-left: 20px;margin-right: 20px;" >
                <div style=" text-align: center;" class="kcsz">
                  <img :src="item.imgUrl" alt="" style="height: 100%;"> 
                  <p style="font-weight: 600;padding-top: 10px;">{{ item.title  }}</p>
                </div>
            </div>

            </div>
            <div style="float: right;margin: auto 0;width: 30px;margin-left:20px;height: 30px;background: #fff;border-radius: 50%;text-align: center;line-height: 30px;border: 1px solid #ccc;cursor: pointer" @click="rightClick"> <span  class="el-icon-arrow-right"></span></div>
        </div> -->
        <div
          style="width: 75%;height: 400px;margin-top: 50px; margin: 100px auto;display: flex;justify-content: space-around;">
          <div
            style="width: 31%;height: 100%;background-color: #E7F7FF;box-shadow: 0px 0px 21px 0px rgba(0,0,0,0.06);padding: 10px;overflow-y: auto;border-radius: 10px;"
            class="fontTitle">
            <span style="font-size: 16px; font-weight: bold;color: #333;"><img
                style=" vertical-align: middle;margin-right: 10px;" :src="require('@/assets/img/icon/book (1).png')"
                alt="" srcset="">行业动态</span>
            <div style="margin-top: 10px;margin-bottom: 10px;" v-for="(item, index) in fontList" :key="index">
              <p style="font-size: 14px;font-family:auto;font-weight: 400;color: #333333;cursor: pointer;"
                @click="titleClick(fontList, '行业动态', item, index)">{{ item.title }}</p>
              <p style="font-size: 12px;font-weight: 400;color: #666666;text-align: end;">{{ getTime(item.insertTime) }}
              </p>
            </div>

          </div>
          <div
            style="width: 31%;height: 100%;background-color: #DCECFD;box-shadow: 0px 0px 21px 0px rgba(0,0,0,0.06);padding: 10px;overflow-y: auto;border-radius: 10px;"
            class="fontTitle">
            <span style="font-size: 16px; font-weight: bold;color: #333;"><img
                style=" vertical-align: middle;margin-right: 10px;" :src="require('@/assets/img/icon/book (2).png')"
                alt="" srcset="">最新政策</span>
            <div style="margin-top: 10px;margin-bottom: 10px;" v-for="(itemNew, index) in trendsNew" :key="index">
              <p style="font-size: 14px;font-family:auto;font-weight: 400;color: #333333;cursor: pointer;"
                @click="titleClick(trendsNew, '最新政策', itemNew, index)">{{ itemNew.title }}</p>
              <p style="font-size: 12px;font-weight: 400;color: #666666;text-align: end;">{{ getTime(itemNew.insertTime) }}</p>
            </div>
          </div>
          <div
            style="width: 31%;height: 100%;background-color: #EAFBF3;box-shadow: 0px 0px 21px 0px rgba(0,0,0,0.06);padding: 10px;overflow-y: auto;border-radius: 10px;"
            class="fontTitle">
            <span style="font-size: 16px; font-weight: bold;color: #333;"><img
                style=" vertical-align: middle;margin-right: 10px;" :src="require('@/assets/img/icon/book (3).png')"
                alt="" srcset="">校内公告</span>
            <div style="margin-top: 10px;margin-bottom: 10px;" v-for="(itemNotice, index) in campusNotice" :key="index">
              <p style="font-size: 14px;font-family:auto;font-weight: 400;color: #333333;cursor: pointer;"
                @click="titleClick(campusNotice, '校内公告', itemNotice, index)">{{ itemNotice.title }}</p>
              <p style="font-size: 12px;font-weight: 400;color: #666666;text-align: end;">{{ getTime(itemNotice.insertTime )}}</p>
            </div>
          </div>
        </div>
        <!-- 推荐课程 -->
        <div
          style="width: 75%;margin: 30px auto;text-align: center;font-size: 30px;font-weight: 600;margin-top: 3%;margin-bottom: 2%;">
          推荐课程
          <span style="cursor:pointer;font-size: 12px;color: #666;float: right;" @click="GOcourseTeaching()">
            更多<img style="vertical-align: middle;" :src="require('@/assets/img/index/gengduoicon.png')" alt="" srcset="">
          </span>
        </div>
        <div style="width: 75%;margin: 30px auto; display: flex;justify-content:space-between;">
          <div style="width: 45%;">
            <img style="width: 80%;" :src="`${baseUrl}/${course.coverPic}`" alt="" srcset="">
          </div>
          <div style="width:52%;display: flex;flex-direction: row;flex-wrap: wrap;justify-content: space-between;">
            <div style="cursor:pointer;display: flex;justify-content: flex-start;width: 49%"
              v-for="(item, index) in courseList" :key="index" @click="GOcourseTeachingitem(item.courseId)">
              <div>
                <img width="61" height="70" :src="`${baseUrl}/${item.courseCoverPic}`" alt="" srcset="">
              </div>
              <div style="margin-left: 10px;">
                <p style="font-size: 18px;font-weight: 600;">{{ item.courseName }}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 课程实训 -->
        <div
          style="width: 75%;margin: 30px auto;text-align: center;font-size: 30px;font-weight: 600;margin-top: 3%;margin-bottom: 2%;">
          课程实训
          <span style="font-size: 12px;color: #666;float: right;cursor:pointer;" @click="GOdigitalTraining()">
            更多<img style="vertical-align: middle;" :src="require('@/assets/img/index/gengduoicon.png')" alt="" srcset="">
          </span>
        </div>
        <div style="width: 75%;margin: 30px auto; display: flex;justify-content:space-between;">
          <div style="width:52%;display: flex;flex-direction: row;flex-wrap: wrap;justify-content: space-between;">
            <div style="cursor:pointer;display: flex;justify-content: flex-start;width: 49%"
              v-for="(item, index) in practiceList" :key="index" @click="GOdigitalTrainingitem(item.trainingId)">
              <div>
                <img width="61" height="70" :src="`${baseUrl}/${item.trainingCoverPic}`" alt="" srcset="">
              </div>
              <div style="margin-left: 10px;">
                <p style="font-size: 18px;font-weight: 600;">{{ item.trainingName }}</p>
              </div>
            </div>
          </div>

          <div style="width: 45%;">
            <img style="width: 80%;" :src="`${baseUrl}/${practice.coverPic}`" alt="" srcset="">
          </div>
        </div>
        <!-- 系统实训 -->
        <div style="text-align: center;font-size: 30px;font-weight: 600;margin-top: 3%;margin-bottom: 2%;">系统实训</div>
        <div
          style="width: 75%;margin: 30px auto;height: 400px;background-color: #fff;box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.09);">
          <el-carousel :interval="3000" arrow="always" height="400px">
            <el-carousel-item v-for="(item, index) in xitongData" :key="index"
              style="display: flex;flex-direction: row;justify-content: space-around;align-items: flex-start;">
              <div
                style="cursor:pointer;display: flex;justify-content: center;flex-direction: column;align-items: center;"
                v-for="(item2, index2) in item" :key="index2" @click="GOProductionitem(item2)">
                <div
                  style="width:200px;height: 40px;color:#fff;background: #76C8F6;text-align: center;border-radius: 0px 0px 16px 16px;line-height: 40px;">
                  {{ item2.systemExperienceName }}
                </div>
                <img style="width: 84%;" :src="`${baseUrl}/${item2.pictureUrl}`" alt="" srcset="">
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <!-- <div style="text-align: center;font-size: 30px;font-weight: 600;margin-top: 3%;margin-bottom: 2%;" >合作院校</div>
          <div style="width: 75%;margin: 30px auto; display: flex;align-items: center;">

          <div style="float: left;margin: auto 0;width: 30px;margin-right:20px;height: 30px;background: #fff;border-radius: 50%;text-align: center;line-height: 30px;border: 1px solid #ccc;cursor: pointer" @click="leftClickPartners"> <span class="el-icon-arrow-left"></span></div>
            <div style="width: 100%; display: flex;align-items: center;justify-content: space-between;;overflow-x: scroll;padding-top: 25px;" ref="Partners" class="scroll_wrapper">
              
              <div v-for="(item,index) in listPartners" :key="index" style="display: flex;align-items: center;justify-content: center;margin-left: 20px;margin-right: 20px;" >
                <el-card shadow="always" class="kcsz" style="padding-left: 50px;padding-right: 50px;">
                <div style=" text-align: center;width: 200px;" >
                  <img :src="item.imgUrl" alt="" style="width: 100px;height: 80px;">
                  <p style="font-weight: 600;padding-top: 10px;">{{ item.title  }}</p>
                </div>
              </el-card>
            </div>
         
            </div>
            <div style="float: right;margin: auto 0;width: 30px;margin-left:20px;height: 30px;background: #fff;border-radius: 50%;text-align: center;line-height: 30px;border: 1px solid #ccc;cursor: pointer" @click="rightClickPartners"> <span  class="el-icon-arrow-right"></span></div>
          </div> -->
      </div>
    </div>
  </div>
</template>
<script>
let Base64 = require("js-base64").Base64;
import { encryptDes } from "@/js/des";
import { reactive, toRefs, onMounted, onUnmounted, nextTick, ref, getCurrentInstance, onBeforeMount } from "vue";
import Swiper from "swiper"; // 引入库
import "swiper/css/swiper.css"; // 引入样式文件，注意5和6版本的样式文件不一致
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { setStorage } from "@/js/common";
import { baseUrl } from "@/js/util.js";
// import router from "../../router/index.js";
import { F_Get_shufflingPictureList, F_Get_phaseRecommendedList, F_Get_bstatiSticalrowse } from "@/js/teachingSquare";
import { get_homeNoticeSettingList } from "@/js/homeListApi.js";
import { getTimeAgo } from '@/js/timeRules'
export default {
  setup() {
    const router = useRouter();
    const store = useStore();
    const wrapperCon = ref();
    const Partners = ref();
    const state = reactive({
      bList: [
        // {
        //   pictureUrl:require('@/assets/img/index/bannerlunbo.png')
        // }
      ],
      baseUrl: baseUrl,
      digitalDesign: [],
      intelligentConstruction: [],
      smartConstruction: [],
      smartMaintenance: [],
      course: {},//推荐课程左侧大图
      courseList: [],//推荐课程右侧小图列表
      practice: {},//推荐课程左侧大图
      practiceList: [],//推荐课程右侧小图列表

      xitongData: [
        {
          name: '智慧工地',
          name2: '数据中心',
          imgUrl: require('@/assets/img/index/xitong1.png'),
          imgUrl2: require('@/assets/img/index/xitong1.png')
        }, {
          name: '智慧工地',
          name2: '数据中心',
          imgUrl: require('@/assets/img/index/xitong1.png'),
          imgUrl2: require('@/assets/img/index/xitong1.png')
        }
      ],
      object1: {},
      object2: {},
      object3: {},
      object4: {},
      fontList: [],
      trendsNew: [],
      campusNotice: [],
      imgList: [
        {
          imgUrl: require('@/assets/img/index/weitu (6).png'),
          title: '北京大兴国际机场工程'
        }, {
          imgUrl: require('@/assets/img/index/weitu1.png'),
          title: '“中国天眼”FAST工程'
        }, {
          imgUrl: require('@/assets/img/index/weitu (3).png'),
          title: '北盘江大桥工程'
        }, {
          imgUrl: require('@/assets/img/index/weitu (4).png'),
          title: '阳江核电站工程'
        }, {
          imgUrl: require('@/assets/img/index/weitu (5).png'),
          title: '西电东送工程'
        }, {
          imgUrl: require('@/assets/img/index/weitu (6).png'),
          title: '北京大兴国际机场工程'
        }, {
          imgUrl: require('@/assets/img/index/weitu1.png'),
          title: '“中国天眼”FAST工程'
        }, {
          imgUrl: require('@/assets/img/index/weitu (3).png'),
          title: '北盘江大桥工程'
        }, {
          imgUrl: require('@/assets/img/index/weitu (4).png'),
          title: '阳江核电站工程'
        }, {
          imgUrl: require('@/assets/img/index/weitu (5).png'),
          title: '西电东送工程'
        }
      ],
      listPartners: [
        {
          imgUrl: require('@/assets/img/index/school2.png'),
          title: '安徽理工大学'
        }, {
          imgUrl: require('@/assets/img/index/school3.png'),
          title: '武汉理工大学'
        }, {
          imgUrl: require('@/assets/img/index/school4.png'),
          title: '湖北文理学院'
        }, {
          imgUrl: require('@/assets/img/index/school5.png'),
          title: '湖南工业大学'
        }, {
          imgUrl: require('@/assets/img/index/school6.png'),
          title: '武汉轻工大学'
        }, {
          imgUrl: require('@/assets/img/index/school1.png'),
          title: '河北工程大学'
        }, {
          imgUrl: require('@/assets/img/index/school2.png'),
          title: '安徽理工大学'
        }, {
          imgUrl: require('@/assets/img/index/school3.png'),
          title: '武汉理工大学'
        }, {
          imgUrl: require('@/assets/img/index/school4.png'),
          title: '湖北文理学院'
        }, {
          imgUrl: require('@/assets/img/index/school5.png'),
          title: '湖南工业大学'
        }, {
          imgUrl: require('@/assets/img/index/school6.png'),
          title: '武汉轻工大学'
        }, {
          imgUrl: require('@/assets/img/index/school1.png'),
          title: '河北工程大学'
        },
      ],
      timer: null,
      timerPartners: null,
      ifautoplay: false,
      ifloop: false,
    });
    const setbclist = (arr) => {

      store.commit("Setbclist", arr);
      console.log(store.state.bclist, arr)
      setStorage("bclist", arr);
    }
    onMounted(() => {
      methods.getRotationImg();
      methods.getF_Get_phaseRecommendedList();
      // methods.getF_Get_bstatiSticalrowse();
      methods.getFontList();
      methods.getTrendsNew();
      methods.getCampusNotice();
      methods.getpracticeList();
      methods.getXitongData();



    });
    const methods = {
      GOcourseTeaching() {
        store.commit("SetnarbarMenuActive", 2);
        setStorage("narbarMenuActive", 2);
        router.push('/home/courseTeaching')
      },
      GOdigitalTraining() {
        store.commit("SetnarbarMenuActive", 3);
        setStorage("narbarMenuActive", 3);
        router.push('/home/digitalTraining')
      },
      GOcourseTeachingitem(courseId) {
        store.commit("SetnarbarMenuActive", 2);
        setStorage("narbarMenuActive", 2);
        setStorage('courseId', courseId);
        router.push('/home/courseDetailsCopy');
      },
      GOdigitalTrainingitem(Id) {
        store.commit("SetnarbarMenuActive", 3);
        setStorage("narbarMenuActive", 3);
        setStorage('digitalTrainingId', Id);
        router.push('/home/systemDetails')
      },
      GOProductionitem(item2) {
        store.commit("SetnarbarMenuActive", 4);
        setStorage("narbarMenuActive", 4);
        // console.log(item2,'item2');
        setStorage('intelitem', item2);
        router.push('/home/intelligentConstructionDetail')
      },
      // 行业动态/最新政策/校内公告点击
      titleClick(List, TabTitle, item, index) {
        store.commit("SetnarbarMenuActive", 7);
        setStorage("narbarMenuActive", 7);
        setStorage("List", List);
        setStorage("TabTitle", TabTitle);
        setStorage("ListIndex", index);
        setStorage("ListItem", item);
        if (item.hnsType == 2) {
          window.open(item.content, "_blank")
        } else {
          router.push({ name: 'industryDynamicsDetails' });
        }

      },
      getTime(s){
        return getTimeAgo(s)
      },
      // 点击左
      leftClick() {
        let left = wrapperCon.value.scrollLeft
        console.log(left);
        let num = 0
        clearInterval(state.timer)
        state.timer = null
        state.timer = setInterval(() => {
          //   !left:已经滚动到最左侧
          //   一次性滚动距离（可调节）
          if (!left || num >= 300) {
            // 停止滚动
            clearInterval(state.timer)
            state.timer = null
            return
          }
          // 给滚动盒子元素赋值向左滚动距离
          wrapperCon.value.scrollLeft = left -= 30
          // 保存向左滚动距离（方便判断一次性滚动多少距离）
          num += 30
        }, 30)
      },
      // 点击右
      rightClick() {
        let left = wrapperCon.value.scrollLeft
        // 保存元素的整体宽度
        let scrollWidth = wrapperCon.value.scrollWidth
        // 保存元素的可见宽度
        let clientWidth = wrapperCon.value.clientWidth
        let num = 0
        clearInterval(state.timer)
        state.timer = setInterval(() => {
          // 已经滚动距离+可见宽度
          // left+clientWidth>=scrollWidth：滚动到最右侧
          // num>=300一次性滚动距离
          if (left + clientWidth >= scrollWidth || num >= 300) {
            clearInterval(state.timer)
            return
          }
          // 给滚动盒子元素赋值向左滚动距离
          wrapperCon.value.scrollLeft = left += 30
          // 保存向左滚动距离（方便判断一次性滚动多少距离）
          num += 30
        }, 30)

      },
      leftClickPartners() {
        let left = Partners.value.scrollLeft
        console.log(left);
        let num = 0
        clearInterval(state.timerPartners)
        state.timerPartners = null
        state.timerPartners = setInterval(() => {
          //   !left:已经滚动到最左侧
          //   一次性滚动距离（可调节）
          if (!left || num >= 300) {
            // 停止滚动
            clearInterval(state.timerPartners)
            state.timerPartners = null
            return
          }
          // 给滚动盒子元素赋值向左滚动距离
          Partners.value.scrollLeft = left -= 30
          // 保存向左滚动距离（方便判断一次性滚动多少距离）
          num += 30
        }, 30)
      },
      rightClickPartners() {
        let left = Partners.value.scrollLeft
        // 保存元素的整体宽度
        let scrollWidth = Partners.value.scrollWidth
        // 保存元素的可见宽度
        let clientWidth = Partners.value.clientWidth
        let num = 0
        clearInterval(state.timerPartners)
        state.timerPartners = setInterval(() => {
          // 已经滚动距离+可见宽度
          // left+clientWidth>=scrollWidth：滚动到最右侧
          // num>=300一次性滚动距离
          if (left + clientWidth >= scrollWidth || num >= 300) {
            clearInterval(state.timerPartners)
            return
          }
          // 给滚动盒子元素赋值向左滚动距离
          Partners.value.scrollLeft = left += 30
          // 保存向左滚动距离（方便判断一次性滚动多少距离）
          num += 30
        }, 30)
      },
      // 点击banner跳转
      imgClick(item) {
        console.log(item);
        window.open(item.contentUrl, "_blank")
      },
      // 获取轮播图
      async getRotationImg() {
        let res = await F_Get_shufflingPictureList()
        if (res.data.code == 1) {
          state.bList = res.data.data;
          console.log(state.bList);
          if (state.bList.length > 1) {
            state.ifautoplay = true
            state.ifloop = true
          } else if (state.bList.length <= 1) {
            state.ifautoplay = false
            state.ifloop = false
          }
          nextTick(() => { initSwiper(); })

        }
      },
      GObannerItem(item) {
        console.log(item)
        if (item.link) {
          window.open(item.link)
        }
        
      },
      //获取行业动态
      getFontList() {
        let datas = {
          homeNoticeSettingType: 1,
        }
        get_homeNoticeSettingList(datas).then((res) => {
          if (res.data.code == 1) {
            state.fontList = res.data.data
            // console.log(state.fontList);
          }
        })

      },
      //获取最新政策
      getTrendsNew() {
        let datas = {
          homeNoticeSettingType: 2,
        }
        get_homeNoticeSettingList(datas).then((res) => {
          if (res.data.code == 1) {
            state.trendsNew = res.data.data
            // console.log(state.trendsNew);
          }
        })

      },
      //获取校内公告
      getCampusNotice() {
        let datas = {
          homeNoticeSettingType: 3,
        }
        get_homeNoticeSettingList(datas).then((res) => {
          if (res.data.code == 1) {
            state.campusNotice = res.data.data
            // console.log(state.campusNotice);
          }
        })

      },
      // 获取课程推荐
      getF_Get_phaseRecommendedList() {
        let datas = {
          recommendType: 1,
        }
        F_Get_phaseRecommendedList(datas).then(res => {
          if (res.data.code == 1) {
            state.course = res.data.data.homeRecommendPictureData;
            state.courseList = res.data.data.list;


            //  array.forEach(item => {
            //   item.forEach(element => {
            //       if (element.phase == 1) {
            //         state.intelligentConstruction.push(element)
            //         // 数字设计
            //       } else if (element.phase == 2) {
            //         state.digitalDesign.push(element)
            //         // 智慧施工
            //       } else if (element.phase == 3) {
            //         state.smartConstruction.push(element)
            //         // 智慧运维
            //       } else {
            //         state.smartMaintenance.push(element)
            //       }
            //   });
            //  });

          }
        })
      },
      // 获取课程实训
      getpracticeList() {
        let datas = {
          recommendType: 2,
        }
        F_Get_phaseRecommendedList(datas).then(res => {
          if (res.data.code == 1) {
            state.practice = res.data.data.homeRecommendPictureData;
            state.practiceList = res.data.data.list;
            console.log(res.data.data);

          }
        })
      },
      //获取系统实训
      getXitongData() {
        let datas = {
          recommendType: 3,
        }
        F_Get_phaseRecommendedList(datas).then(res => {
          if (res.data.code == 1) {
            state.xitongData = res.data.data;
            let newDataList = [];
            let current = 0
            if (state.xitongData && state.xitongData.length > 0) {
              for (let i = 0; i <= state.xitongData.length - 1; i++) {
                if (i % 2 !== 0 || i === 0) {	//数据处理成几张展示
                  if (!newDataList[current]) {
                    newDataList.push([state.xitongData[i]])
                  } else {
                    newDataList[current].push(state.xitongData[i])
                  }
                } else {
                  current++
                  newDataList.push([state.xitongData[i]])
                }
              }
            }
            state.xitongData = [...newDataList]

            console.log(state.xitongData);

          }
        })
      },

      // 获取课程统计
      getF_Get_bstatiSticalrowse() {
        F_Get_bstatiSticalrowse().then(res => {
          if (res.data.code == 1) {
            if (res.data.data[0]) {
              state.object1 = res.data.data[0];
            }
            if (res.data.data[1]) {
              state.object3 = res.data.data[1];
            }
            if (res.data.data[2]) {
              state.object2 = res.data.data[2];
            }
            if (res.data.data[3]) {
              state.object4 = res.data.data[3];
            }

          }
        })
      },

      // 跳转楼上后台
      backfn: () => {
        // 钢筋集中加工和配送----绿色低碳科创云平台
        // let baseUrl = 'http://47.95.149.159/gjjgpsweb'
        // let uname = "SuperAdmin";
        // let id = "890c4192-0b18-4ce6-ae41-5f258d8ef887";

        // 设计院管理系统
        let baseUrl = 'http://47.95.149.159/sjyweb'
        let uname = "SuperAdmin";
        let id = "CEA52225-D79E-42B1-A006-474629638C99";
        let Base64desuname = encryptDes(uname, 'dfe5921b');
        let Base64desid = encryptDes(id, 'dfe5921b');
        let Base64url = Base64.encode(baseUrl + "/main");
        let enduname = escape(Base64desuname);
        let enddesid = escape(Base64desid);
        let enddesurl = escape(Base64url);
        let goURL = baseUrl + "/systemviews/signin/pkpmsignin.html?un=" + enduname + "&ei=" + enddesid + "&redirecturl=" + enddesurl;
        console.log(goURL)
        window.open(goURL);
      },
      toDetail: (item, index, type) => {
        let arr = []
        if (type == 1) {
          arr = [
            {
              text: "智慧设计",
              nindex: "3",
              path: "/home/digitalDesign",
              back: false
            },
            {
              text: "课程介绍",
              nindex: "",
              path: "",
              back: false
            }
          ]
        } else if (type == 2) {
          arr = [
            {
              text: "智能制造",
              nindex: "4",
              path: "/home/intelligentProduction",
              back: false
            },
            {
              text: "课程介绍",
              nindex: "",
              path: "",
              back: false
            }
          ]
        } else if (type == 3) {
          arr = [
            {
              text: "智慧施工",
              nindex: "5",
              path: "/home/intelligentConstruction",
              back: false
            },
            {
              text: "课程介绍",
              nindex: "",
              path: "",
              back: false
            }
          ]
        } else if (type == 4) {
          arr = [
            {
              text: "智慧运维",
              nindex: "6",
              path: "/home/intelligentOAM",
              back: false
            },
            {
              text: "课程介绍",
              nindex: "",
              path: "",
              back: false
            }
          ]
        }

        item.id = item.courseId
        setbclist(arr);
        // store.commit("SetnarbarMenuActive", "5s");
        // setStorage("narbarMenuActive", "5s");
        setStorage('detailData', item)
        store.commit('SetdetailData', item)
        router.push("/home/intelligentConstructionDetail");



      },
      activeNavChange: (type) => {
        console.log(type);
        let nindex = '';
        let path = '';
        if (type == 1) {
          nindex = "3";
          path = "/home/digitalDesign";
        } else if (type == 2) {
          nindex = "4";
          path = "/home/intelligentProduction";
        } else if (type == 3) {
          nindex = "5";
          path = "/home/intelligentConstruction";
        } else if (type == 4) {
          nindex = "6";
          path = "/home/intelligentOAM";
        }
        if (nindex && path) {
          store.commit("SetnarbarMenuActive", nindex);
          setStorage("narbarMenuActive", nindex);
          router.push(path);
        }
      },
    };
    const initSwiper = () => {
      new Swiper(".swiper-container", {
        slidesPerView: 'auto',
        spaceBetween: 30,
        speed: 3000,
        loop: state.ifloop,
        autoplay: state.ifautoplay, //自动切换

        autoplay: {
          delay: 3000, //自动切换的时间间隔，单位ms
          disableOnInteraction: false //用户操作swiper之后，是否禁止autoplay
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });

    };

    onBeforeMount(() => {

    })

    onUnmounted(() => {

    });
    // provide('popfalse',state.popfalse)
    return {
      ...toRefs(state),
      ...methods,
      wrapperCon,
      Partners
    };
  },
  components: {},
};
</script>
<style lang="scss" scoped>
:deep .el-carousel__button {
  background-color: #76C8F6 !important;
}

.scroll_wrapper::-webkit-scrollbar {
  display: none;
}

.fontTitle::-webkit-scrollbar {
  display: none;
}

.kcsz:hover {
  cursor: pointer;
  /* transform属性 */
  transform: translate(0, -20px);
  transition-duration: 300ms;
}

#index {
  height: 100%;
  position: relative;
  // top: -60px;
  background: #fff;

  .dtolr {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .i-wrap {
    width: 100%;

    // margin: 0 auto;
    .banner-c {
      width: 100%;
      height: 350px;
      margin-bottom: 20px;

      .swiper-container {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: revert;
        }

        .bltext {
          position: absolute;
          bottom: 0;
          background: #00000080;
          width: 100%;
          font-size: 14px;
          padding: 10px;
          color: #fff;
        }
      }
    }
  }

  :deep {
    .swiper-pagination-bullets {
      // bottom: 8px;
      // left: auto;
      // width: auto;
      // right: 30px;
    }

    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      border-radius: 8px;
      box-sizing: border-box;
      border: 1px solid #fff;
      opacity: 1;
      background: transparent;
      margin: 0 10px;

      &.swiper-pagination-bullet-active {
        width: 25px;
        height: 8px;
        border-radius: 8px;
        box-sizing: border-box;
        border: 1px solid #fff;
        opacity: 1;
        background: #fff;
        margin: 0 10px;
      }
    }
  }

  .dList {
    // padding: 30px 0 0 0;
    width: 100%;
    margin: 0 auto;

    .dtoll {
      width: 78px;
    }

    .oneLine2 {
      width: 1200px;
      margin: 0 auto;
      display: flex;

      // justify-content: space-between;
      .doll {
        width: 980px;
        height: 305px;
        padding: 20px 0;
        box-sizing: border-box;
        background: #fff;
        overflow: hidden;

        .d-title {
          display: flex;
          justify-content: space-between;

          .dttext {
            font-size: 16px;
            font-weight: bold;
            color: #0200F9;
          }

          .mb {
            font-size: 12px;
            color: #666666;
            cursor: pointer;
          }

          margin: 0 0 15px;
        }

        .pl-c {
          display: flex;

          .pco {

            // width: 20%;
            // margin: 0 5% 0 0;
            &:last-child {
              margin: 0;
            }

            &:nth-child(4) {
              // display: none;
            }

            .img-c {
              // height: 187px;
              // box-sizing: border-box;
              // border: 1px solid #eee;
              width: 224px;
              height: 168px;
              background: #ffffff;
              border: 1px solid #e2e2e2;
              margin-right: 21px;
              box-sizing: border-box;

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }

            .pcotext {
              color: #333333;
              font-size: 14px;
              padding: 15px 0 10px;
              font-weight: bold;
              width: 210px;
            }
          }

          .pco:hover {
            cursor: pointer;
            /* transform属性 */
            transform: translate(0, -10px);
            transition-duration: 500ms;

            .pcotext {
              color: #0200F9;
              font-size: 14px;
              padding: 15px 0 10px;
              font-weight: bold;
              width: 210px;
            }
          }
        }
      }

      .dolr {
        height: 235px;
        width: 220px;
        padding: 20px;
        box-sizing: border-box;
        background: #fff;

        .dolr-titil {
          font-size: 16px;
          font-weight: bold;
          color: #0200F9;
          margin: 0 0 4px;
        }

        .dolr-tlist {
          .dtoneline {
            display: flex;
            justify-content: space-between;
            color: #333333;
            font-size: 14px;
            line-height: 32px;
          }
        }
      }
    }

    .oneLine {
      width: 1200px;
      margin: 0 auto;
      display: flex;

      // justify-content: space-between;
      .doll {
        width: 980px;
        height: 305px;
        padding: 20px 0;
        box-sizing: border-box;
        background: #EBEBFF;
        overflow: hidden;

        .d-title {
          display: flex;
          justify-content: space-between;

          .dttext {
            font-size: 16px;
            font-weight: bold;
            color: #0200F9
          }

          .mb {
            font-size: 12px;
            color: #666666;
            cursor: pointer;
          }

          margin: 0 0 15px;
        }

        .pl-c {
          display: flex;

          .pco {

            // width: 20%;
            // margin: 0 5% 0 0;
            &:last-child {
              margin: 0;
            }

            .img-c {
              // height: 187px;
              // box-sizing: border-box;
              // border: 1px solid #eee;
              width: 224px;
              height: 168px;
              background: #ffffff;
              border: 1px solid #e2e2e2;
              margin-right: 21px;
              box-sizing: border-box;

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }

            .pcotext {
              color: #333333;
              font-size: 14px;
              padding: 15px 0 10px;
              font-weight: bold;
              width: 210px;
            }
          }

          .pco:hover {
            cursor: pointer;
            /* transform属性 */
            transform: translate(0, -10px);
            transition-duration: 500ms;

            .pcotext {
              color: #0200F9;
              font-size: 14px;
              padding: 15px 0 10px;
              font-weight: bold;
              width: 210px;
            }
          }
        }
      }

      .dolr {
        height: 235px;
        width: 220px;
        padding: 20px;
        box-sizing: border-box;
        background: #EBEBFF;

        .dolr-titil {
          font-size: 16px;
          font-weight: bold;
          color: #0200F9;
          margin: 0 0 4px;
        }

        .dolr-tlist {
          .dtoneline {
            display: flex;
            justify-content: space-between;
            color: #333333;
            font-size: 14px;
            line-height: 32px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1600px) {
  #index {
    .i-wrap {

      // width: 1400px;
      .banner-c {
        height: 520px;
        margin-bottom: 20px;
      }
    }

    .dList {
      .dtoll {
        width: 78px;
      }

      .oneLine,
      .oneLine2 {
        width: 1400px;

        .doll {
          height: 305px;
          width: 1200px;
          overflow: hidden;

          .pl-c {

            .pco {
              &:nth-child(4) {
                display: inline-block;
              }

              .img-c {
                margin-right: 18px;
              }
            }

            .pco:hover {
              cursor: pointer;
              /* transform属性 */
              transform: translate(0, -10px);
              transition-duration: 300ms;

              .pcotext {
                color: #0200F9;
                font-size: 14px;
                padding: 15px 0 10px;
                font-weight: bold;
                width: 210px;
              }
            }
          }
        }

        .dolr {
          height: 274px;
          width: 200px;
          padding: 20px 0px 20px 18px;

          .dolr-titil {
            margin: 0 0 4.5px;
          }

          .dolr-tlist {
            .dtoneline {
              line-height: 32px;
            }
          }
        }
      }
    }
  }
}</style>
